import { daEventCenter } from 'public/src/services/eventCenter/index'

export const ageLimitAnalysis =  {
  exposePopup ({ expose_type }) {
    daEventCenter.triggerNotice({
      daId: '2-28-1',
      extraData: {
        expose_type,
      },
    })
  },
  clickPopup ({ expose_type, button_type }) {
    daEventCenter.triggerNotice({
      daId: '2-28-2',
      extraData: {
        expose_type,
        button_type,
      },
    })
  },
}
